export { getFormattedDate, toYear, toUrlFriendlyParticle, toReadingTime }

function toReadingTime(minutes) {
  if (minutes > 1) return `${minutes} minutes read`
  else return `${minutes} minute read`
}

function toiOSFriendly(date) {
  // iOS doesn't support dates in this manner 2018-10-01 22:11
  // it expects dates to be in a simplified version of ISO-8601
  // http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
  // which looks like this: 2018-10-01T22:11
  return date.replace(' ', 'T')
}

function getFormattedDate(date, { includeYear = true } = {}) {
  // Dates are formatted like this: 2018-10-01 22:11
  const formattedDate = new Date(toiOSFriendly(date)).toDateString()
  // toDateString => "Mon Oct 01 2018"
  const datePieces = formattedDate.split(' ').slice(1, 4)
  const [month, day, year] = datePieces

  if (!month && !day && !year)
    console.warning(`Error formatting date ${date} => ${formattedDate}`)

  // Workaround: this is a workaround for a weird behavior
  // I found in iOS. For some reason the date particles
  // are parsed as undefined
  if (!month && !day && !year) return ``
  else if (includeYear) return `${month} ${day}, ${year}`
  else return `${month} ${day}`
}

function toYear(dateString) {
  const year = new Date(toiOSFriendly(dateString)).getUTCFullYear()

  // Workaround: this is a workaround for a weird behavior
  // I found in iOS. For some reason the year particle is
  // handled as NAN
  if (!year) console.warning(`Error formatting date ${dateString} => ${year}`)
  return year || ''
}

function toUrlFriendlyParticle(particle) {
  return particle.replace(/\./g, 'dot')
}
