import React from 'react'
import * as styles from './archive-list.module.css'

import { Link } from 'gatsby'
import Tag from '../../components/tags/Tag'

import { getFormattedDate, toYear, toReadingTime } from '../../utils/formatting'
import { sortDescending } from '../../utils/sorting'

export class ArchiveList extends React.Component {
  render() {
    let classes = `${this.props.classes} ${styles.years}`

    // TODO: review this
    // this is probably why archives is so freakishly slow
    const articlesByYear = groupByYear(this.props.articles)
    return (
      <ul className={classes}>
        {Object.keys(articlesByYear)
          .sort(sortDescending)
          .map((year) => (
            <li key={year} className={styles.year}>
              <header className={styles.yearLabel}>{year}</header>
              <ul className={styles.articles}>
                {articlesByYear[year].map((a, idx) => (
                  <ArchiveItem article={a} key={idx} />
                ))}
              </ul>
            </li>
          ))}
      </ul>
    )
  }
}

class ArchiveItem extends React.Component {
  render() {
    const { article } = this.props
    const {
      date,
      title,
      categories = [],
      description = '',
    } = article.frontmatter
    const readingTime = toReadingTime(article.timeToRead)
    const formattedDate = getFormattedDate(date, {
      includeYear: false,
    })
    return (
      <li className={styles.article}>
        <time className={styles.date}>{formattedDate}</time>
        <span className={styles.readingtime}>
          {'• '}
          {readingTime}
        </span>
        <h2>
          <Link to={article.fields.slug}>{title}</Link>
        </h2>
        <p>{description}</p>
        <footer>
          {categories &&
            categories.map((category, idx) => <Tag key={idx}>{category}</Tag>)}
        </footer>
      </li>
    )
  }
}

function groupByYear(articles) {
  // looks like articles becomes undefined for some reason for the creativity category
  // review this...
  // perhaps categories don't work
  return articles.reduce((byYear, a) => {
    const year = toYear(a.frontmatter.date)
    if (!byYear[year]) {
      byYear[year] = []
    }
    byYear[year].push(a)

    return byYear
  }, {})
}
