import React from 'react'
import * as styles from './Tag.module.css'

import { Link } from 'gatsby'
import { toUrlFriendlyParticle } from '../../utils/formatting'

/** A badge is a UI component to decorate or mark some content with a
 * descriptive word like "FEATURED" or "SERIES". */
export const Badge = ({
  children,
  small,
  subdued,
  comic,
  shadow,
  className,
  ...rest
}) => {
  let classes = styles.badge
  if (className) classes = `${classes} ${className}`
  if (small) classes = `${classes} ${styles.small}`
  if (subdued) classes = `${classes} ${styles.subdued}`
  if (comic) classes = `${classes} ${styles.comic}`
  if (shadow) classes = `${classes} ${styles.shadow}`

  return (
    <span className={classes} {...rest}>
      {children}
    </span>
  )
}

/** A tag is a UI component that looks like a badge and has acts as a link to a
 * given tag in the blog. */
const Tag = ({ children, small, subdued, alert, ...rest }) => {
  let classes = styles.tag
  if (small) classes = `${classes} ${styles.small}`
  if (subdued) classes = `${classes} ${styles.subdued}`
  if (alert) classes = `${classes} ${styles.alert}`

  const urlFriendlyCategory = toUrlFriendlyParticle(children)
  const categoryUrl = `/blog/categories/${urlFriendlyCategory}`
  return (
    <Link className={classes} to={categoryUrl} {...rest}>
      {children}
    </Link>
  )
}

export default Tag
