// extracted by mini-css-extract-plugin
export var accentcolor = "#ffb53b";
export var alert = "Tag-module--alert--033a0";
export var badge = "Tag-module--badge--561d3";
export var basecolor = "#00cc6d";
export var bluebasecolor = "#0181eb";
export var comic = "Tag-module--comic--c8b34";
export var redcolor = "#f44336";
export var shadow = "Tag-module--shadow--abe80";
export var small = "Tag-module--small--329b2";
export var subdued = "Tag-module--subdued--c397d";
export var subduedcolor = "#11111f";
export var tag = "Tag-module--tag--3505d";