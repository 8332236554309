import React from 'react'
import Layout from '../components/layout/Layout'
import * as styles from './Category.module.css'

import { graphql } from 'gatsby'
import { ArchiveList } from '../components/archive-list/archive-list'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

export default class CategoryTemplate extends React.Component {
  render() {
    const { category } = this.props.pageContext

    const { data } = this.props
    const articles = data.allMarkdownRemark.edges.map((e) => e.node)
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout>
        <article className={styles.categories}>
          <SEO title={`Articles about ${category} | ${siteTitle}`} />
          <h1
            style={{
              paddingBottom: rhythm(1 / 2),
              borderBottom: '0.5px dashed #dedede',
            }}
          >
            Articles about{' '}
            <span className={styles.categoryname}>{category}</span>
          </h1>
          <ArchiveList articles={articles} />
        </article>
      </Layout>
    )
  }
}

// BlogpostsByCategory
export const pageQuery = graphql`
  query ($category: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            categories
          }
          excerpt
          fields {
            slug
            isBlogArticle
          }
          timeToRead
        }
      }
    }
  }
`
